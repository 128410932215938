@import 'variables/colors';
@import 'common';

.rcw-conversation-container {
  .rcw-header {
    // background-color: $turqois-1;
    background-color: $white;
    border-radius: 10px 10px 0 0;
    // border-top: 1px solid #dddddd;
    // color: $white;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .rcw-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .rcw-title {
    font-size: 18px;
    margin: 0;
    padding: 5px 0;
  }

  .rcw-close-button {
    display: none;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle;
  }

  .rcw-footer {
    // background-color: #f4f7f9;
    background-color: #e7ebed;
    text-align: center;
    padding: 5px 0;
    font-size: 10px;
    line-height: 10px;
    border-radius: 0 0 10px 10px;
  }
}

.rcw-full-screen {
  .rcw-header {
    @include header-fs;
  }

  .rcw-title {
    @include title-fs;
  }

  .rcw-close-button {
    @include close-button-fs;
  }

  .rcw-close {
    @include close-fs;
  }
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    .rcw-header {
      @include header-fs;
    }

    .rcw-title {
      @include title-fs;
    }

    .rcw-close-button {
      @include close-button-fs;
    }

    .rcw-close {
      @include close-fs;
    }
  }
}
