.phone-space {
  height: 700px;
  width: 350px;
  margin: 30px auto;
  text-align: left;
  top: 15%;
  min-height: 750px;
}

.window {
  background-color: transparent;
  height: 660px;
  width: 305px;
  margin: 20px 23px 20px 22px;
  position: absolute;
  z-index: -10;
}

.phone-image {
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
  -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(10%, transparent), to(rgba(250, 250, 250, 0.1)));
  height: inherit;
  width: inherit;
  position: absolute;
  overflow: auto;
  pointer-events: none;
}

@use postcss-nested;

.chat p {
  max-width: 180px;
  word-wrap: break-word;
  margin: 0px 5px 10px 5px;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  padding: 10px 20px;
  border-radius: 25px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 20px;
  }
}

.chat {
  width: auto;
  min-width: 265px;
  display: flex;
  padding: 0 20px;
  flex-direction: column-reverse;
  position: absolute;
  height: 455px;
  z-index: -5;
  background-color: white;
  overflow: auto;
  top: 105px;
}

.phone-messages {
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}

.phone-message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .phone-message {
  /* margin-right: 25%; */
  background-color: #eee;
  position: relative;
}

.yours .phone-message:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .phone-message:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .phone-message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%) fixed;
  position: relative;
}

.mine .phone-message:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%) fixed;
  border-bottom-left-radius: 15px;
}

.mine .phone-message:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.header {
  background-color: #f0f0f0;
  height: 105px;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom: dimgrey 0.3pt solid;
  position: absolute;
  z-index: 3;
}

.icons {
  width: auto;
}

.left-icons {
  margin-top: 12px;
  margin-left: 19px;
  display: flex;
  float: left;
  font-size: 0.6em;
}

.right-icons {
  margin-top: 12px;
  margin-right: 13px;
  float: right;
  display: flex;
  font-size: 0.7em;
}

.messenger-header {
  clear: both;
}

.back-arrow {
  display: flex;
  float: left;
  z-index: 15;
  color: #0b93f6;
  font-size: 1.5em;
  margin-top: 15px;
}

.user-detail {
  display: block;
  margin: 0 auto;
  padding-top: 15px;
}

.user-icon {
  background-image: linear-gradient(#939393, #7b7b7b);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: white;
  font-family: 'Helvetica Neue', serif;
  font-size: 0.75em;
  text-align: center;
  line-height: 35px;
  margin: auto;
}

.user-name {
  font-size: 0.5em;
  color: #000000;
  font-family: 'Helvetica Neue', serif;
  text-align: center;
  margin: auto;
  margin-top: 6px;
  padding: 0;
}

.right-arrow {
  margin-bottom: -2px;
  color: #7b7b7b;
  font-size: 1.1em;
}

.clock {
}

.input-bar {
  background-color: #fcfcfc;
  width: 100%;
  height: 40px;
  position: absolute;
}

.text-default {
  color: #9a9aa0;
  font-size: 14px;
  margin-left: 15px;
}

.text-active {
  color: #000;
  font-size: 0.6em;
  margin-left: 15px;
}

.text-box {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: #adadad 1px solid;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  width: 75%;
}

.voice-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: dimgrey;
  width: 25px;
  height: 23px;
  text-align: center;
  color: #fcfcfc;
  font-size: 1em;
  position: relative;
  float: right;
  margin-right: 3px;
  margin-top: 0px;
  padding-top: 2px;
}

.home-bar {
  height: 4px;
  border-radius: 2px;
  width: 35%;
  background-color: black;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.input-view {
  background-color: #d7d7dc;
  position: absolute;
  margin: auto;
  bottom: 0;
  width: 100%;
  height: 100px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 8;
}
