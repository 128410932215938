@import 'common';
@import 'variables/colors';
@import 'animation';
@import '~emoji-mart/css/emoji-mart.css';

.rcw-conversation-container {
  // border-radius: 10px;
  box-shadow: 0px -2px 15px -2px #5554;
  min-width: 100%;
  max-width: 90vw;
  position: relative;

  // WIP
  margin: 0 5px 5px;
  width: calc(100% - 10px);
  min-width: calc(100% - 10px);
  border-radius: 10px;
  // end WIP

  &.active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.hidden {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

.rcw-conversation-resizer {
  cursor: col-resize;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.emoji-mart-preview {
  display: none;
}

.rcw-full-screen {
  .rcw-conversation-container {
    @include conversation-container-fs;
  }
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    @include conversation-container-fs;
  }
}
