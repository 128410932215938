@import 'animation';
@import 'common';

.rcw-widget-container {
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 99;
}

.rcw-full-screen {
  @include widget-container-fs;
}

.rcw-previewer .rcw-message-img {
  cursor: pointer;
}

.rcw-close-widget-container {
  height: max-content;
  width: max-content;
}
