@import 'variables/colors';

.loader {
  margin: 10px;
  // display: none;
  visibility: collapse;

  &.active {
    visibility: visible;
    // display: flex;
  }
}

// .loader-container {
//   background-color: $grey-2;
//   border-radius: 10px;
//   padding: 15px;
//   max-width: 215px;
//   text-align: left;
// }

.loader-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 20px;
  left: 0;
  width: calc(100% - 20px);
  height: 50px;
  margin: 0;
  padding: 10px;
  background-color: #f5f7f9;
  z-index: 1;
}

#loader-canvas {
  width: 100%;
  height: 50px;
  max-height: 50px;
}

.loader-dots {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: $grey-0;
  margin-right: 2px;
  animation: bounce 0.5s ease infinite alternate;

  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}
